import { Form } from '@remix-run/react';
import LogoutSVG from '~/assets/svg/logout';

export default function LogoutForm() {
  return (
    <Form method="post" action="/auth/logout">
      <button className="btn btn-outline btn-primary btn-xs">
        Log Out
        <LogoutSVG />
      </button>
    </Form>
  );
}
