import { Form } from '@remix-run/react';
import LoginSVG from '~/assets/svg/login';

export default function LoginForm() {
  return (
    <Form method="post" action="/auth/login">
      <button className="btn btn-outline btn-primary btn-xs">
        Log In
        <LoginSVG />
      </button>
    </Form>
  );
}
